/* body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} */

html,
body,
#root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Space Mono', monospace;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

p {
	margin-block-start: 0.5em;
	margin-block-end: 0.5em;
}
